import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
import findId from "../../../static/img/bidra-dokumentasjon/component-overview-find-id.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Viktig at informasjonen er oppdatert`}</h2>
    <p>{`Alle må kunne stole på at det som står i dokumentasjonen er oppdatert og riktig. Hjelp oss med å holde dokumentasjonen oppdatert når du gjør endringer eller legger til noe nytt og spennende.`}</p>
    <h2>{`Tilgang til dokumentasjonen`}</h2>
    <p>{`Akkurat nå ligger selve dokumentasjonen i et annet repo enn selve designsystem-koden. Ta kontakt med designsystem-teamet for å få hjelp til tilgang.`}</p>
    <h2>{`Endring av innhold`}</h2>
    <p>{`Alt innhold i dokumentasjonen er skrevet i markdown-filer. For å opppdatere en eksisterende side:`}</p>
    <ol>
      <li parentName="ol">{`Finn riktig markdown-fil. Alle filene skal ha navn som matcher den faktiske sidetittelen`}</li>
      <li parentName="ol">{`Gjør endringene du ønsker i markdown`}</li>
      <li parentName="ol">{`Opprett en pull request`}</li>
    </ol>
    <p>{`Designsystem-teamet reviewer pull requesten din, og du kan merge så fort den har blitt godkjent.`}</p>
    <h2>{`Component-overview`}</h2>
    <p>{`Component-overview er navnet på den siden som vises når man kjører opp designsystemet-repoet lokalt. Her listes alle eksemplene ut og man har mulighet til å gå inn på hvert enkelt. Det er også live-reload sånn at du kan se endringer du gjør i en komponent. `}</p>
    <p>{`I «component-overview/examples» mappa finner du også alle filene til eksemplene som brukes i dokumentasjonen. Alle eksemplene react-komponenter som har som mål å vise de ulike variantene og bruksområdene til ffe-komponentene. I tillegg til dokumentasjonen, bruker vi også eksemplene til å UU-teste komponentene i spesifikke scenarioer.`}</p>
    <h3>{`Legg til eksempel`}</h3>
    <p>{`Ønsker du å legge til nytt eksempel som skal brukes lokalt eller i dokumentasjonen. Følger du disse stegene: `}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Lag ny jsx-fil med et beskrivende navn i riktig sub-mappe.
Hvilken sub-mappe avhenger av hvilken komponent du ønsker å legge til eksempel av, og hvilken pakke den ligger under.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Legg til den koden du ønsker skal vises i eksempelet i filen.
Prøv å inkluder minst mulig kode for at eksempelet skal være tydeligst mulig. All kode du skriver inn i filen, vil vises i "vis kode" seksjonen, dersom man legger inn eksempelet i dokumentasjonen. `}</p>
      </li>
    </ol>
    <h2>{`Vis eksempel i dokumentasjonen`}</h2>
    <p>{`For å vise eksempler i dokumentasjonen, har vi laget en react-komponent som heter DSExample.
Denne importeres globalt, så det er ikke noe behov for å importere den i hver enkelt markdown-fil. `}</p>
    <p>{`Legg inn følgende kode, hvor enn du ønsker at eksempelet skal dukke opp i innholdet`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`<DSExample name=""/>`}</code></pre></div>
    <p>{`Name er i dette tilfelle id'en til eksempelet som du finner ved å gå inn på eksempelet i component-overview. `}</p>
    <img src={findId} alt="Viser plassering av id på component overview" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      